import tableGetters from "@/store/templates/table/getters";

export default {
  ...tableGetters,
  company: state => companyId => {
    return state.company[companyId];
  },
  totalAmount: state => state.totalAmount,
  invoicePdfDownloadable: state => invoiceId =>
    state.entity[invoiceId].pdfDownloadable
};
